import { keys } from 'ramda';
import { isNumeric } from "rxjs/internal-compatibility";

export enum UI_MODULE {
  DASHBOARD = 'Dashboard',
  CONTRACT = 'Contract Browser',
  PSL = 'PSL',
  'Request Cockpit' = 'Request Cockpit',
  DOCFLOW = 'Request Cockpit',
  CB = 'Competitive bidding',
  TENDER_CALENDAR = 'Tender Calendar',
  APPROVAL = 'Approval simplification',
  MRP = 'MRP/Ariba',
  NBS_COCKPIT = 'NBS Cockpit'
}

export enum FEEDBACK_MODULE {
  DASHBOARD = 'Dashboard',
  CONTRACT = 'Contract Browser',
  PSL = 'PSL',
  'Request Cockpit' = 'Request Cockpit',
  CB = 'Competitive bidding',
  TENDER_CALENDAR = 'Tender Calendar',
  APPROVAL = 'Approval simplification',
  MRP = 'MRP/Ariba',
  EASY_REQUEST = 'Easy Request (for request creation)'
}

export enum NEW_FEEDBACK_MODULE {
  DASHBOARD = 'Dashboard',
  CONTRACT = 'Contract Browser',
  PSL = 'PSL',
  'Request Cockpit' = 'Request Cockpit',
  CB = 'Competitive bidding',
  TENDER_CALENDAR = 'Tender Calendar',
  APPROVAL = 'Approval simplification',
  MRP = 'MRP/Ariba',
  EASY_REQUEST = 'Easy Request (for request creation)',
  NBS_COCKPIT_OB_VALIDATION = 'NBS Cockpit - OB Validation',
  NBS_COCKPIT_BLOCKED_PARKED = 'NBS Cockpit - Blocked & Parked',
  AMC = 'Configuration Cockpit'
}

export class UiModule {

  static toSelect() {
    return keys(FEEDBACK_MODULE).filter((k => !isNumeric(k) && FEEDBACK_MODULE[k] !== FEEDBACK_MODULE.DASHBOARD)).map((key) => ({ value: FEEDBACK_MODULE[key], label: FEEDBACK_MODULE[key] }));
  }

  static toFilter() {
    return keys(FEEDBACK_MODULE).filter((k => !isNumeric(k) && FEEDBACK_MODULE[k] !== FEEDBACK_MODULE.DASHBOARD)).map(key => ({ id: FEEDBACK_MODULE[key], title: key }))
  }

  static toNewSelect() {
    return keys(NEW_FEEDBACK_MODULE).filter((k => !isNumeric(k) && NEW_FEEDBACK_MODULE[k] !== NEW_FEEDBACK_MODULE.DASHBOARD)).map((key) => ({ value: NEW_FEEDBACK_MODULE[key], label: NEW_FEEDBACK_MODULE[key] }));
  }

  static toNewFilter() {
    return keys(NEW_FEEDBACK_MODULE).filter((k => !isNumeric(k) && NEW_FEEDBACK_MODULE[k] !== NEW_FEEDBACK_MODULE.DASHBOARD)).map(key => ({ id: NEW_FEEDBACK_MODULE[key], title: key }))
  }

  static toAppCode(code: UI_MODULE) {
    switch (code) {
      case UI_MODULE.APPROVAL: return 'AFS';
      default: return '';
    }
  }
}
